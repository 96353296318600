/*!
 * Bootstrap v4.0.0-beta (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */


// override of some default variables
$enable-rounded:            true; //default
$enable-shadows:            false; //default
$enable-gradients:          false; //default
$enable-transitions:        true; //default
$enable-hover-media-query:  false; //default
$enable-grid-classes:       true; //default
$enable-print-styles:       true; //default

$border-radius: .15rem;
$border-radius-lg: .25rem;
$border-radius-sm: .1rem;

@import "functions";
@import "variables";
@import "mixins";
@import "print";
//@import "reboot";
@import "type";
@import "images";
@import "code";
@import "grid";
@import "tables";
@import "forms";
@import "buttons";
@import "transitions";
@import "dropdown";
@import "button-group";
@import "input-group";
@import "custom-forms";
@import "nav";
@import "navbar";
@import "card";
@import "breadcrumb";
@import "pagination";
@import "badge";
@import "jumbotron";
@import "alert";
@import "progress";
@import "media";
@import "list-group";
@import "close";
@import "modal";
@import "tooltip";
@import "popover";
@import "carousel";
@import "utilities";
@import "overrides";
