.table {
  &.text-smaller {
    font-size: .8em;
  }
}

@include media-breakpoint-up(xl) {
  .container {
    max-width: 100%;
  }
}
